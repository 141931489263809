/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Row } from "react-bootstrap";
// import DataTable from "react-data-table-component";
import PaginationComponent from "../../../components/tables/PaginationComponent";
import { customStyles } from "../../../components/tables/TableStyles";
import { Fetch, IFetchProps } from "../../../services/Fetch";
import { useSelector } from "react-redux";
import { AlertError } from "../../../components/SweetAlerts/Alerts";
import { PERMISSIONS } from "../../../data/permissions";
import { Brand } from "../../../components/forms/dataForms";
import { endpoints } from "../../../data/endpoints";
import DataTable from "react-data-table-component";
import ConvertJsonToExcel from "../../../services/ConvertJsonToExcel";
import { convertTemplates } from "./data";

const ModifyDownload = () => {
  const { token, Permisos: userPermissions } = useSelector((state: any) => state.auth.user);

  const [dataForm, setDataForm] = useState<any>({});
  const [data, setData] = React.useState<any>([]);
  const [columns, setColumns] = React.useState<any>([]);
  const [total, setTotal] = React.useState<number>(0);
  const [templatesTypes, setTemplatesTypes] = useState<any>([]);

  const [since, setSince] = React.useState<number>(0);
  const [limit, setLimit] = React.useState<number>(10);

  // const [dataStudent, setDataStudent] = useState<any>({});

  const setColumnsData = (datatable: any) => {
    const cols = Object.keys(datatable[0]);
    const columnsData = [];

    for (let index = 0; index < cols.length; index++) {
      const col = cols[index];

      columnsData.push({
        name: col,
        selector: (row: any) => [row[col]],
        sortable: true,
        cell: (row: any) => (
          <span className="d-flex align-items-center">
            <span
              className="text-light tx-13 py-1 px-4"
              contentEditable={false}
              onBlur={(e: any) => handleKeyUp(e, row)}
              id={col}
            >
              {row[col] ? row[col] : ""}
            </span>
          </span>
        ),
      });
    }
    setColumns(columnsData);
    setData(datatable);
  };

  const handleKeyUp = (e: any, row: any) => {
    const { innerText, id } = e.target;
    console.log(innerText, id);
  };
  const getTemplates = async () => {
    const propsFetch: IFetchProps = {
      Endpoint: `${endpoints.templates.list[9]}`,
      method: "GET",
      token,
    };
    const response: any = await Fetch(propsFetch);

    if (response.statusCode && response.statusCode === 200) {
      setTemplatesTypes(
        response.data.map((element: any) => {
          return {
            label: element.name,
            value: element.code,
          };
        })
      );
    }
  };

  const getLists = async () => {
    if (!dataForm.templates || dataForm.templates === 0) {
      return AlertError("Por Favor seleccione una tabla primero");
    }
    const propsFetch: IFetchProps = {
      Endpoint: `${endpoints.filters.templates}?date=${dataForm.date}&code=${dataForm.templates}&page=${since}&take=${limit}`,
      method: "GET",
      token,
    };
    const response: any = await Fetch(propsFetch);

    if (response.statusCode && response.statusCode === 200) {
      if (response.data.length) {
        setColumnsData(response.data);
        setTotal(response.metaData.totalItems);
      } else {
        AlertError("No se encontro informacion de la tabla");
      }
    }
  };

  const downloadReportComplete = async () => {
    const propsFetch: IFetchProps = {
      Endpoint: `${endpoints.filters.templates}?date=${dataForm.date}&code=${dataForm.templates}&page=${since}&take=${limit}`,
      method: "GET",
      token,
    };
    const response: any = await Fetch(propsFetch);

    if (response.statusCode && response.statusCode === 200) {
      if (response.data.length) {
        ConvertJsonToExcel(response.data, {
          name: convertTemplates[dataForm.templates],
          semester: dataForm.date ? `${dataForm.date}` : "Todos",
        });
      } else {
        AlertError("No se encontro informacion de la tabla");
      }
    }
  };

  const handleChangeInput = (e: any) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeSelect = (option: any) => {
    setDataForm({
      ...dataForm,
      [option.Name]: option.value,
    });
  };

  const handleSubmitSearch = (e: any) => {
    e.preventDefault();
    getLists();
  };

  useEffect(() => {
    getTemplates();
  }, []);

  React.useEffect(() => {
    if (!dataForm.templates || dataForm.templates === 0) return;
    getLists();
  }, [since, limit]);

  return (
    <div>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Descargar actualizaciones</span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb>
            <Breadcrumb.Item className=" tx-15" href="#">
              Administracion
            </Breadcrumb.Item>
            <Breadcrumb.Item>Plantillas SNIES</Breadcrumb.Item>
            <Breadcrumb.Item active aria-current="page">
              Descargar actualizaciones
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Row>
        <Col md={12} xl={12} xs={12} sm={12}>
          <Card>
            <Card.Header>
              <h3 className="card-title">Lista de registros</h3>
            </Card.Header>
            <Card.Body>
              <div>
                <div className="table-responsive box-list-register">
                  <Form onSubmit={handleSubmitSearch}>
                    <Row>
                      <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                        <FormGroup className="form-group">
                          <Form.Label htmlFor="templates">Plantillas</Form.Label>
                          <Brand
                            isClearable={false}
                            Options={templatesTypes}
                            Name="templates"
                            Placeholder="Seleccionar"
                            Onchange={handleChangeSelect}
                            defaultValue={templatesTypes.find((item: any) => item.value === dataForm.templates)}
                          />
                        </FormGroup>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                        <FormGroup className="form-group">
                          <Form.Label htmlFor="date">Fecha</Form.Label>
                          <Form.Control
                            placeholder=""
                            type="date"
                            name="date"
                            onChange={handleChangeInput}
                            value={dataForm.date ?? ""}
                            disabled={!dataForm.templates}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button variant="" className="btn btn-primary btn-block" type="submit" disabled={!dataForm.date}>
                      Buscar
                    </Button>
                  </Form>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12} xl={12} xs={12} sm={12}>
          <Card>
            <Card.Header>
              <h3 className="card-title">Lista de actualizaciones</h3>
            </Card.Header>
            <Card.Body>
              <div>
                {data.length !== 0 && (
                  <div>
                    {userPermissions.some((value: any) => value === PERMISSIONS.dowmloadReportRate) && (
                      <Button className="btn btn-primary mb-3 me-2" onClick={downloadReportComplete}>
                        Descargar Excel
                      </Button>
                    )}
                  </div>
                )}
                <div className="table-responsive ">
                  <span className="datatable">
                    <DataTable columns={columns} data={data} customStyles={customStyles} />
                    {data.length > 0 && (
                      <PaginationComponent
                        since={since}
                        setSince={setSince}
                        limit={limit}
                        setLimit={setLimit}
                        total={total}
                      />
                    )}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ModifyDownload;

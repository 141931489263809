/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/prefer-default-export */

import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { AlertCharging, AlertError } from "../components/SweetAlerts/Alerts";
import { LOGOUT } from "../redux/actions/action";

const url = process.env.REACT_APP_API_URL;

export interface IFetchProps {
  Endpoint: string;
  method: "GET" | "POST" | "PUT" | "DELETE";
  data?: any;
  token?: string;
  notLoading?: boolean;
}

export async function Fetch(props: IFetchProps) {
  let response: any = { statusCode: null };
  if (!props.notLoading) {
    AlertCharging();
  }
  await fetch(url + props.Endpoint, {
    method: props.method,
    headers: {
      "Content-Type": "application/json",
      Authorization: props.token || "",
    },
    body: JSON.stringify(props.data),
  })
    .then((res) => res.json())
    .then((res) => {
      Swal.close();
      if (res.statusCode === 200 || res.statusCode === 201) {
        response = res;
      }
      if (res.statusCode === 400) {
        AlertError(res.msg || res.message);
        response = res;
      }
      if (res.statusCode === 401) {
        logout();
      }
      if (res.statusCode === 404) {
        AlertError(res.msg || res.message);
        response = res;
      }
      if (res.statusCode === 410) {
        AlertError(res.msg || res.message);
        response = res;
      }
      if (res.statusCode === 500) {
        AlertError(res.msg || res.message);
        response = res;
      }
    })
    .catch((error) => {
      AlertError(`ERROR EN EL SERVIDOR - Contactar con soporte`);
      response = error;
    });
  return response;
}

const logout = () => {
  const dispatch = useDispatch();
  return dispatch(LOGOUT());
};

import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { AlertCharging, AlertSuccess} from "../components/SweetAlerts/Alerts";

// Funcion para convertir el archico Excel a Json con la libreria xlsx
const ConvertJsonToExcel = async (data: any, fileInfo: any) => {
  AlertCharging()
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, `${fileInfo.rate} - ${fileInfo.semester}`, true);
  XLSX.writeFile(workbook, `Listado ${fileInfo.name} - ${fileInfo.semester}.xlsx` )
  Swal.close()
  AlertSuccess("Descarga Exitosa")
};

export default ConvertJsonToExcel;

export const PERMISSIONS = {
  // Permisos para el modulo de usuarios
  writeUser: "write.user",
  readUser: "read.user",
  updateUser: "update.user",
  deleteUser: "delete.user",
  filterUser: "filter.user",
  // Permisos para el modulo de solicitudes de reintegros
  writeApplication: "write.application",
  readApplication: "read.application",
  // Permisos para el modulo de cargue de plantillas
  writeTemplates: "write.template",
  readTemplates: "read.template",
  updateTemplates: "update.template",
  deleteTemplates: "delete.template",
  filterTemplates: "filter.template",
  downloadUpdateTemplates: "update.template",
  // Permisos para el modulo de calculo de tasas
  readRate: "read.tasa",
  updateRate: "update.tasa",
  readReportRate: "read.report.tasa",
  dowmloadReportRate: "download.report.tasa",
  updatePermissions: "download.report.tasa",
};

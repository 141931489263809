import Swal from "sweetalert2";
import spinner2 from "../../assets/sedga/spinner.png";

// Alerta para errores
export const AlertError = (message, icon, title) => {
  const alerta = Swal.fire({
    customClass: "alerts",
    icon: icon || "error",
    title: title || "ERROR",
    text: message,
  });
  return alerta;
};

// alerta para resultados positivos
export const AlertSuccess = (message, time) => {
  const alerta = Swal.fire({
    customClass: "alerts",
    position: "center",
    icon: "success",
    title: message,
    allowOutsideClick: false,
    showConfirmButton: false,
    timer: time || 2000, 
  });
  return alerta;
};

// Alerta con un spinner de carga
export const AlertCharging = (message) => {
  const alerta = Swal.fire({
    customClass: "loader-logo alerts",
    title: "Cargando",
    text: "",
    html: `
        <div>
          <p>Por favor espere un momento...</p>
          <img src=${spinner2} alt={spinner} width="50" />
        </div>
        `,
    showConfirmButton: false,
    allowOutsideClick: false,
  });
  return alerta;
};

// alerta para mostrar el progreso de una accion
export const AlertProgress = () => {
  const alerta = Swal.fire({
    customClass: "alerts",
    title: "Importando la Data!",
    html: "0 - 0",
    allowOutsideClick: false,
    showConfirmButton: false,
  });

  return alerta;
};

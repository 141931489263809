// eslint-disable-next-line
const INIT_STATE: any = {
  isLogged: false,
  user: {
    user: {
      rol: "",
    },
  },
};

const AuthReducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case "SAVE_LOGIN":
      return {
        ...state,
        isLogged: true,
        user: action.payload,
      };
      
    case "CLOSE_LOGIN":
      return {
        ...state,
        isLogged: false,
        user: {
          user: {
            rol: "",
          },
        },
      };
    default:
      return state;
  }
};

export default AuthReducer;

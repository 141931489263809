import React from 'react'
import Load from '../../assets/sedga/spinner.png'

const Loader = () => {
    return (
        <div id="global-loader">
            <img src={Load} className="loader-img" alt="Loader" width={200}/>
        </div>
    )
}

export default Loader;
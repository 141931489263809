export const ADD = (item: any) => {
  return {
    type: "ADD_CART",
    payload: item,
  };
};

// remove items
export const Delete = (item: any) => {
  return {
    type: "RMV_CART",
    payload: item,
  };
};

// Save Information User
export const LOGGED = (item: any) => {
  return {
    type: "SAVE_LOGIN",
    payload: item,
  };
};

// Save Permissions User
export const SET_PERMISISONS = (item: any) => {
  return {
    type: "SET_PERMISISONS",
    payload: item,
  };
};

// Save Information User
export const LOGOUT = () => {
  return {
    type: "CLOSE_LOGIN",
  };
};

// remove individual items
export const REMOVE = (item: any) => {
  return {
    type: "RMV_ONE",
    payload: item,
  };
};
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

// eslint-disable-next-line import/prefer-default-export, react/prop-types
export const PrivateRoute = ({ children, permissions }) => {
  const { isLogged } = useSelector((state) => state.auth);
  const { Permisos: userPermissions } = useSelector((state) => state.auth.user);

  let permission = false;

  if (permissions) {
    permission = userPermissions.some((value) => value === permissions);
  } else {
    permission = true;
  }

  return <>{isLogged && permission ? children : <Navigate to="/" />}</>;
};

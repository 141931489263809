import React from "react";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { FormSelect } from "react-bootstrap";
import "../../App.css"

/*
  La paginacion se realiza a nivel de back-end, ya que las tablas pueden tener miles de registros, mejorando el tiempo de respuesta ya que los registron van llegando en paquetes a medida que se pasa de pagina y segun la cantidad que decida el usuario.
*/

interface IPaginationProps {
  since: number;
  setSince: Function;
  limit: number;
  setLimit: Function;
  total: number,
}

const PaginationComponent = ({ since, setSince, limit, setLimit, total }: IPaginationProps) => {
  const [page, setPage] = React.useState(1)
  const options = [
    {
      label: "10",
      value: 10,
    },
    {
      label: "20",
      value: 20,
    },
    {
      label: "50",
      value: 50,
    },
    {
      label: "Todos",
      value: total,
    },
  ];

  // Funcion para ir a la primera pagina de la tabla
  const handleFirstPageButtonClick = () => {
    setPage(1)
    setSince(0)
  };

  // Funcion para pasar a la anterior pagina
  const handleBackButtonClick = () => {
    setPage(page - 1)
    setSince(Number(since) - Number(limit))
  };

  // Funcion para pasar a la siguiente pagina
  const handleNextButtonClick = () => {
    setPage(page + 1)
    setSince(Number(since) + Number(limit))
  };

  // Funcion para ir a la ultima pagina de la tabla
  const handleLastPageButtonClick = () => {
    const countPages = Number(total) / Number(limit)

    if (Number.isInteger(countPages)) {
      setPage(countPages)
      setSince(limit * (countPages - 1))
    } else {
      setPage(Math.trunc(countPages) + 1)
      setSince(limit * Math.trunc(countPages))
    }

  };

  // Funcion para cambiar la cantidad de filas por pagina
  const handleRowsPerPageChange = (e: any) => {
    setPage(1)
    setSince(0)
    setLimit(e.target.value)
  };

  // Funcion para calcular cual es la ultima pagina segun la cantidad de registros
  const lastPage = () => {
    const countPages = Number(total) / Number(limit)

    if (Number.isInteger(countPages)) {
      if (page === countPages) {
        return true
      }
    } else {
      if (page === Math.trunc(countPages) + 1) {
        return true
      }
    }
    return false
  };

  return (
    <div className="d-flex justify-content-end mt-2">
      <p className="my-auto me-2">Filas Por Pagina</p>
      <FormSelect
        className="form-select-sm"
        style={{ width: "65px", height: "50px", border: "none" }}
        onChange={(e) => handleRowsPerPageChange(e)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </FormSelect>
      <p className="my-auto mx-2">{`${since + 1} - ${limit * page} de ${total}`}</p>
      <IconButton
        className={since === 0 ? "text-muted" : "text-light btn-pagination"}
        onClick={handleFirstPageButtonClick}
        disabled={since === 0}
        aria-label="Primera Pagina"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        className={since === 0 ? "text-muted" : "text-light btn-pagination"}
        onClick={handleBackButtonClick}
        disabled={since === 0}
        aria-label="Pagina Anterior"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton className="text-light fs-6">
        {page}
      </IconButton>
      <IconButton
        className={lastPage() ? "text-muted" : "text-light btn-pagination"}
        onClick={handleNextButtonClick}
        disabled={lastPage()}
        aria-label="Pagina Siguiente"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        className={lastPage() ? "text-muted" : "text-light btn-pagination"}
        onClick={handleLastPageButtonClick}
        disabled={lastPage()}
        aria-label="Ultima Pagina"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

export default PaginationComponent;

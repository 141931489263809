export const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      background: "var(--primary-bg-color)",
      border: "1px solid white",
      textTransform: "uppercase",
      justifyContent: "center",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
      textAlign: "center"
    }
  }
};
import { combineReducers } from "redux";
import AuthReducer from "../reducers/authReducer";
import cartreducer from "../reducers/reducer";

const RootReducers = combineReducers({
  cartreducer,
  auth: AuthReducer,
});

export default RootReducers;

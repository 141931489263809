import React from "react";
import { useCallback } from "react";
import ParticlesJS from "react-tsparticles";
import { loadFull } from "tsparticles";

interface PropsParticles {
  color?: "primary" | "white";
}

const Particles = ({ color = "white" }: PropsParticles) => {
  const particlesInit = useCallback(async (engine: any) => {
    await loadFull(engine);
  }, []);

  // extraer el color primario de la aplicacion y guardarlo
  const colorP = {
    primary: getComputedStyle(document.documentElement)
      .getPropertyValue("--primary-bg-color")
      .trim(),
    white: "#ffffff"
  }

  return <ParticlesJS
    id="tsparticles"
    init={particlesInit}
    className="tsparticles"
    options={{
      particles: {
        number: {
          value: 70, // Cantidad de particulas
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: colorP[color],
        },
        shape: {
          type: "circle",
          stroke: {
            width: 2,
            color: colorP[color],
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 3,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: colorP[color],
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 0.5,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "repulse",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 150,
            line_linked: {
              opacity: 1,

            },
          },
          bubble: {
            distance: 400,
            size: 20,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 250,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    }} />;
};

export default Particles;
